@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.service-wrapper{
  padding: 120px $mainPadding;
  .service-header-block{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .service-block-title{
      width: 46%;
      display: flex;
      align-items: flex-start;
      color: black;
      font-size: 50px;
      font-family: SpaceMono;
      font-style: normal;
      font-weight: 700;
      line-height: 110.1%;
      letter-spacing: -1px;
      text-transform: uppercase;
    }
    .service-block-description{
      width: 30%;
      display: flex;
      align-items: flex-end;
      color: #8B8B8B;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 131.9%;
    }
  }

  .services-block{
    width: 100%;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    :nth-child(2){
      .service-item-title{
        white-space: nowrap;
      }
    }
    &:hover{
      .service-item-wrapper:first-child{
        height: 490px;
        width:calc(94% / 4);
        position: relative;
        overflow: hidden;
        .service-item-title{
          display: flex;
          font-size: 40px;
          font-family: sans-serif;
          font-weight: 700;
          line-height: 126.6%;
          text-transform: uppercase;
          color: transparent;
          -webkit-text-stroke: 1px white;
          rotate: -90deg;
          position: relative;
          bottom: 220px;
          white-space: nowrap;
          @media screen and (max-width: 2000px){
            bottom: 180px;
          }
          @media screen and (max-width: 1750px){
            bottom: 160px;
          }
          @media screen and (max-width: 1500px){
            bottom: 140px;
          }
        }
        .service-item-texts{
          position: absolute;
          //bottom: 240px;
          left: 50%;
          transform: translate(-50%, -50%);
          flex-direction:column;
          justify-content:center;
          margin-left:10px;
          color:white;
          display: flex;
          .service-hover-title{
            display: none;
            font-size: 40px;
            font-weight: 700;
            line-height: 126.6%;
            text-transform: uppercase;
            color: transparent;
            -webkit-text-stroke: 1px white;
            font-family:inherit;
          }
          .service-item-description{
            display: none;
            color: #D1D1D1;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 131.9%;
            transition-delay:.1s;
          }
          div {
            position: relative;
            transition:.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity .5s ease-out;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding: 100px $mainPadding;
    .service-header-block{
      flex-direction: column;
      .service-block-title{
        width: 100%;
        color:#000;
        font-family: SpaceMono;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -0.6px;
        text-transform: uppercase;
      }
      .service-block-description{
        width: 100%;
        color: #8B8B8B;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 131.9%;
        margin-top: 22px;
      }
    }
    .services-block{
      flex-direction: column;
      .service-item-title{
        rotate: unset!important;
        bottom: 104px!important;
        left: 30px;
        white-space: unset;
      }
      &:hover{
        .service-item-wrapper:first-child{
          height:160px;
          width:100%;
          overflow: hidden;
          .service-item-texts{
            width: 76%;
            left: 30px!important;
            bottom: 26px!important;
            transform: translateY(0px);
            -webkit-transform: translateY(0px);
          }
        }
      }
    }
  }
}