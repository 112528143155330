@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.ticker-wrapper{
  width: 100%;
  .marquee-container{
    background-color:$brandColor;
    padding: 16px 22px;
    .marquee{
      min-width: unset!important;
    }
    .ticker-item{
      color: #FFF;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      padding-left: 40px;
      height: 20px;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 800px){
    .marquee-container{
      .ticker-item{
        font-size: 14px;
      }
    }
  }
}