@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.events-page-wrapper{
  overflow: hidden;
  .members-events-wrapper{
    margin: 100px 0 150px 0;
    padding: 60px $mainPadding 62px;
    background: #FAFAFA;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .members-events-header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .members-events-title{
        color:#000;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
        text-transform: uppercase;
      }
      .members-events-description{
        color:#8B8B8B;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        width: 384px;
      }
    }
    .members-events-blocks{
      display: flex;
      align-items: center;
      gap: 60px;
      margin-top: 80px;
      width: 100%;
      .members-events-card{
        width:calc(100% / 3 - 38px);
      }
      &.loading{
        min-height: 521px;
      }
    }
    .pagination-event{
      margin-top: 100px;
      display: flex;
      align-items: center;
      .ant-pagination-prev{
        height: unset;
        .prev_btn{
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            path{
              stroke: #B9B9B9;
            }
          }
          &:hover{
            svg{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
      }
      .ant-pagination-next{
        height: unset;
        .next_btn{
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            path{
              stroke: #B9B9B9;
            }
          }
          &:hover{
            svg{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
      }
      .ant-pagination-item{
        height: unset;
        a{
          color: #B9B9B9;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &:hover{
          background-color:transparent;
        }
      }
      .ant-pagination-item-active{
        background-color: transparent;
        border-color: transparent;
        height: unset;
        a{
          color:$brandColor;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &:hover{
          background-color:transparent;
        }
      }
    }
  }
  .blog-events-wrapper{
    margin-bottom: 100px;
    padding: 40px $mainPadding;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .blog-events-header{
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 652px;
      .blog-events-title{
        color:#000;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
        text-transform: uppercase;
      }
      .blog-events-description{
        color:#8B8B8B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-top: 22px;
      }
    }
    .ant-tabs {
      display: flex;
      align-items: center;

      .ant-tabs-nav {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            .ant-tabs-tab-btn {
              color: #262626;
              font-family: Montserrat;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 48.99px;
              letter-spacing: -0.44px;
            }
          }

          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $brandColor;
              font-family: Montserrat;
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: 48.99px;
              letter-spacing: -0.44px;
            }
          }

          .ant-tabs-ink-bar {
            background: $brandColor;
          }
        }
      }
    }
    .blog-events-block{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 60px;
      gap: 38px;
      .blogs-events-card{
        width:calc(100% / 3 - 38px);
        margin-top: 38px;
      }

      &.loading{
        min-height: 1155px;
      }
    }
    .pagination-article{
      margin-top: 100px;
      display: flex;
      align-items: center;
      .ant-pagination-prev{
        height: unset;
        .prev_btn{
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            path{
              stroke: #B9B9B9;
            }
          }
          &:hover{
            svg{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
      }
      .ant-pagination-next{
        height: unset;
        .next_btn{
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            path{
              stroke: #B9B9B9;
            }
          }
          &:hover{
            svg{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
      }
      .ant-pagination-item{
        height: unset;
        a{
          color: #B9B9B9;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &:hover{
          background-color:transparent;
        }
      }
      .ant-pagination-item-active{
        background-color: transparent;
        border-color: transparent;
        height: unset;
        a{
          color:$brandColor;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &:hover{
          background-color:transparent;
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .members-events-wrapper{
      margin: 62px 0;
      padding: 38px $mainPadding;
      .members-events-header{
        flex-direction: column;
        align-items: unset;
        .members-events-title{
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
          text-align: left;
        }
        .members-events-description{
          color:#8B8B8B;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 22px;
          width: 100%;
          text-align: left;
        }
      }
      .members-events-blocks{
        margin-top: 40px;
        flex-direction: column;
        .members-events-card{
          width: 100%;
          margin-top: 62px;
        }
      }
    }
    .blog-events-wrapper{
      align-items: unset;
      .blog-events-header{
        width: 100%;
        margin-bottom: 60px;
        .blog-events-title{
          text-align: center;
          font-family: SpaceMono;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
          text-transform: uppercase;
        }
        .blog-events-description{
          color:#8B8B8B;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 22px;
        }
      }
      .ant-tabs{
        align-items: unset;
      }
      .blog-events-block{
        margin-top: 20px;
        .blogs-events-card{
          width: 100%;
          margin-top: 62px;
        }
      }
    }
  }
}
