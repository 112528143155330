@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";


.partners-wrapper {
  overflow: hidden;
  min-height: 720px;

  .header-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;

    .top {
      width: 100%;
      height: 124px;
      background-color: black;
    }

    .content {
      width: 100%;
      min-height: 300px;
      position: relative;
      background: linear-gradient(90deg, #000 -102.22%, rgba(140, 83, 246, 0.05) -15.59%, rgba(140, 83, 246, 0.05) 45.07%, rgba(138, 161, 239, 0.25) 100%);
      display: flex;
      justify-content: center;

      .inner {
        padding-top: 61px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .header-title {
          font-family: SpaceMono;
          margin: 0;
          font-size: 50px;
          font-weight: 700;
          line-height: 63.3px;
          text-transform: uppercase;
          color: white;
          text-align: center;
          margin-bottom: 22px;
        }

        .header-description {
          font-family: Montserrat;
          max-width: 60%;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: white;
          margin-bottom: 20px;
        }
      }
    }
  }

  .partners_list_wrapper {
    padding: 62px $mainPadding;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .ant-tabs {
      display: flex;
      align-items: center;
      margin-bottom: 64px;

      .ant-tabs-nav {
        margin-bottom: 0;

        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding: 0;

            .ant-tabs-tab-btn {
              color: #262626;
              font-family: Montserrat;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 48.99px;
              letter-spacing: -0.44px;
            }
          }

          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $brandColor;
            }
          }

          .ant-tabs-ink-bar {
            background: $brandColor;
          }
        }
      }
    }

    .partners_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .partners_list-card {
        width: calc(94% / 4);
      }

      @media screen and (max-width: 1350px) {
        .partners_list-card {
          width: calc(94% / 3);
        }
      }
      @media screen and (max-width: 1050px) {
        margin-top: 42px;
        .partners_list-card {
          width: calc(94% / 2);
        }
      }
      @media screen and (max-width: 700px) {
        margin-top: 42px;
        .partners_list-card {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .header-block {
      .top {
        height: 110px;
      }

      .content {
        min-height: 200px;

        .inner {
          padding-top: 48px;

          .header-title {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 6px;
          }

          .header-description {
            max-width: 100%;
            padding: 0 15px;
            font-size: 13px;
            line-height: 19px;
          }
        }
      }
    }
    .partners_list_wrapper {
      padding: 24px $mainPadding;

      .ant-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              .ant-tabs-tab-btn {
                font-size: 16px;
                line-height: 40px;
              }
            }
          }
        }
      }

    }
  }
}

