@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.packages-page-wrapper {
  overflow: hidden;
  min-height: 100vh;

  .packages-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;

    .top {
      width: 100%;
      height: 124px;
      background-color: black;
    }

    .content {
      width: 100%;
      min-height: 300px;
      position: relative;
      background: radial-gradient(101.11% 229.12% at -1.11% 100%, rgba(244, 105, 62, 0.3) 0%, rgba(140, 83, 246, 0.05) 42.84%, rgba(140, 83, 246, 0.05) 72.83%, rgba(138, 161, 239, 0.25) 100%);
      display: flex;
      justify-content: center;

      .inner {
        padding-top: 61px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .page-title {
          font-family: SpaceMono;
          margin: 0;
          font-size: 50px;
          font-weight: 700;
          line-height: 63.3px;
          text-transform: uppercase;
          background: linear-gradient(90deg, #EB7854 0%, #D962C5 46.67%, #88A4F1 100%);
          color: transparent;
          background-clip: text;
          text-align: center;
          margin-bottom: 22px;
        }

        .sub-title {
          font-family: Montserrat;
          max-width: 50%;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          color: white;
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }
  }

  .page_content {
    padding: 62px $mainPadding 0;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .ant-tabs {
      display: flex;
      align-items: center;

      .ant-tabs-nav {
        margin-bottom: 0;
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding: 0;

            .ant-tabs-tab-btn {
              color: #262626;
              font-family: Montserrat;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 48.99px;
              letter-spacing: -0.44px;
            }
          }

          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $brandColor;
            }
          }

          .ant-tabs-ink-bar {
            background: $brandColor;
          }
        }
      }
    }

    .packages_wrapper {
      box-sizing: border-box;
      width: 100%;
      padding: 82px 0 160px;
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;

      .package-card-wrapper {
        box-sizing: border-box;
        width: 25%;
        padding-right: 30px;
        padding-bottom: 25px;
        @media all  and  (max-width: 1300px){
          width: 33.3%;
        }
        @media all  and  (max-width: 800px){
          width: 50%;
        }
        @media all  and  (max-width: 600px){
          width: 100%;
        }
      }
    }
  }

  @media all  and  (max-width: 800px){
    .packages-header {
      .top {
        height: 110px;
      }
      .content{
        min-height: 220px;
        .inner {
          padding-top: 48px;

          .page-title {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 10px;
          }

          .sub-title {
            max-width: 100%;
            padding: 0 15px;
            font-size: 13px;
            line-height: 19px;
            margin-bottom: 48px;
          }
        }
      }
    }
    .page_content {
      padding: 24px $mainPadding 0;
      .ant-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              .ant-tabs-tab-btn {
                font-size: 16px;
                line-height: 40px;
              }
            }
          }
        }
      }
      .packages_wrapper {
        padding: 40px 0 100px;
        margin-right: -10px;
        .package-card-wrapper {
          padding-right: 20px;
          padding-bottom: 15px;
        }
      }
    }
  }

  @media all  and  (max-width: 550px){
    .page_content {
      .ant-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              .ant-tabs-tab-btn {
                font-size: 14px;
                line-height: 35px;
              }
            }
          }
        }
      }
      .packages_wrapper {
        margin-right: 0;
        .package-card-wrapper {
          padding-right: 0;
          padding-bottom: $mainPadding;
        }
      }
    }
  }


  @media all  and  (max-width: 450px){
    .page_content {
      padding: 20px $mainPadding 0;
      .ant-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              margin-left: 15px;
              .ant-tabs-tab-btn {
                font-size: 12px;
                line-height: 35px;
              }
            }
          }
        }
      }
      .packages_wrapper {
        padding: 30px 0 100px;
      }
    }
  }









}
