@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.main-block-wrapper{
  padding: 0 $mainPadding;
  height: 540px;
  background: linear-gradient(140deg, black 56%, $brandColor);
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  .mainBg-text{
    color: rgba(217, 217, 217, 0.03);
    font-family: SpaceMono;
    font-size: 320px;
    font-style: italic;
    font-weight: 700;
    line-height: 126.6%;
    text-transform: uppercase;
    position: absolute;
  }
  .main-block-title{
    font-family: SpaceMono;
    font-size: 110px;
    font-style: italic;
    font-weight: 700;
    line-height: 126.6%;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px white;
    margin-bottom: 140px;
  }

  @media screen and (max-width: 1050px){
    height: 260px;
    .mainBg-text{
      color: rgba(217, 217, 217, 0.03);
      font-family: SpaceMono;
      font-size: 110px;
      font-style: italic;
      font-weight: 700;
      line-height: 126.6%;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .main-block-title{
      font-family: SpaceMono;
      font-size: 55px;
      font-style: italic;
      font-weight: 700;
      line-height: 126.6%;
      text-transform: uppercase;
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 400px){
    .mainBg-text{
      color: rgba(217, 217, 217, 0.03);
      font-family: SpaceMono;
      font-size: 80px;
      font-style: italic;
      font-weight: 700;
      line-height: 126.6%;
      text-transform: uppercase;
    }
    .main-block-title{
      font-family: SpaceMono;
      font-size: 40px;
      font-style: italic;
      font-weight: 700;
      line-height: 126.6%;
      text-transform: uppercase;
    }
  }
}