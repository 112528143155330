@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.service-item-wrapper {
  width: calc(94% / 4);
  height: 490px;
  position: relative;
  transition: 0.5s;
  text-decoration: auto!important;
  overflow: hidden;

  .service-item-img {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .service-gradient {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 138px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 12.50%, #000 88.66%);
  }

  .service-item-title {
    font-size: 40px;
    font-family: sans-serif;
    font-weight: 700;
    line-height: 126.6%;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px white;
    rotate: -90deg;
    position: relative;
    bottom: 220px;
    @media screen and (max-width: 2000px){
      bottom: 180px;
    }
    @media screen and (max-width: 1750px){
      bottom: 160px;
    }
    @media screen and (max-width: 1500px){
      bottom: 140px;
    }
  }
  .service-item-texts {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    color: white;


    .service-hover-title {
      display: none;
      font-size: 40px;
      font-family: sans-serif;
      font-weight: 700;
      line-height: 126.6%;
      text-transform: uppercase;
      color: transparent;
      -webkit-text-stroke: 1px white;
    }

    .service-item-description {
      display: none;
      color: #D1D1D1;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 131.9%;
      transition-delay: .1s;
    }

    div {
      position: relative;
      transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity .5s ease-out;
    }
  }

  &:first-child {
    width: 80%;
    .service-item-title {
      display: none;
    }
    .service-item-texts {
      width: 514px;
      left: 40px;
      bottom: 26px;
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      animation: showAnimation 0s ease-in 5s;
      animation-fill-mode: forwards;

      div {
        left: 0;
        opacity: 1;
      }

      .service-hover-title {
        display: flex;
      }

      .service-item-description {
        display: flex;
      }
    }
  }

  &:last-child {
    .service-item-texts {
      bottom: 26px;
    }
  }

  &:hover {
    width: 80% !important;
    height: 490px;

    &:first-child {
      .service-item-texts {
        margin-left: 4px !important;
        left: 303px !important;
        bottom: -12px !important;
      }
    }

    .service-item-title {
      display: none !important;
    }
    .service-item-texts {
      width: 514px;
      left: 40px;
      bottom: 26px !important;
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      animation: showAnimation 0s ease-in 5s;
      animation-fill-mode: forwards;


      div {
        left: 0;
        opacity: 1;
      }


      .service-hover-title {
        display: flex !important;
      }

      .service-item-description {
        display: flex !important;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    height: 160px;
    &:first-child {
      width: 100%;
      height: 490px;

      .service-item-texts {
        width: 76%;
        left: 30px !important;
        bottom: 26px !important;
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        animation: showAnimation 0s ease-in 5s;
        animation-fill-mode: forwards;
      }
    }
    .service-item-texts {
      top: 50%;
      left: 100px;

      .service-item-title {
        rotate: unset;
      }
    }
    &:first-child {
      .service-item-texts {
        bottom: unset;
        left: 128px;
      }
    }
    &:last-child {
      .service-item-texts {
        bottom: unset;
        left: 83px;
      }
    }
    &:hover {
      width: 100% !important;
      height: 490px !important;

      .service-item-texts {
        width: 76%;
        left: 30px;
        bottom: 26px;
        transform: translateY(0px);
        -webkit-transform: translateY(0px);

        .service-item-title {
          display: none;
        }

        .service-hover-title {
          display: flex;
        }

        .service-item-description {
          display: flex;
        }
      }
    }
  }
}