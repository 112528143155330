@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.items-wrapper{
  width:95%;
  height: 522px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1.5px solid #E8E8E8;
  border-radius: 12px;
  padding: 10px;
  .name-block{
    height: 34px;
    border-radius: 6px 6px 0 0;
    background-color:#1C1C1B;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    color: white;
  }
  .items-header{
    height: 104px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(143, 143, 143, 0.08);
    border-radius: 0 0 6px 6px;
    .items-header-title{
      color: #1C1C1B;
      font-family:Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      overflow-x: scroll;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    .items-header-description{
      color:#8B8B8B;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .items-bottom-block{
    height: 100%;
    padding: 16px 6px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    .bottom-block-info-wrapper{
      height: 360px;
      overflow-y: scroll;
      &::-webkit-scrollbar{
        display: none;
      }
      .info-block{
        overflow: hidden;
        color: #262626;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
      .items-info-block{
        .items-info{
          color: #262626;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          display: flex;
          align-items: center;
          margin-bottom: 18px;
          svg{
            margin-right: 12px;
          }
        }
      }
    }
    .item-visit-block{
      margin-top: 16px;
      .date-options-block{
        color: #8B8B8B;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 50px;
        span{
          color:#262626;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          margin-left: 4px;
          &:first-child{
            margin-left: unset;
          }
        }
      }
      .item-visit-btn{
        width: 100%;
        display: flex;
        align-items: center;
        border: unset;
        background-color: unset;
        color:#1C1C1B;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        cursor: pointer;
        svg{
          margin-left: 8px;
          transition: transform .2s;
          transform: rotate(0deg);
        }
      }
    }
  }

  &:hover{
    .name-block{
      background-color:$brandColor;
    }
    .items-header{
      background-color: rgb(255, 237, 231, 0.8);
      .items-header-title{
        color:$brandColor;
      }
    }
    .items-bottom-block{
      .bottom-block-info-wrapper{
        .items-info-block{
          svg{
            circle{
              fill:$brandColor;
              stroke:$brandColor;
            }
          }
        }
      }
      .item-visit-block{
        .item-visit-btn{
          color:$brandColor;
          svg{
            transform: rotate(0deg) translateX(6px);
            path{
              fill:$brandColor;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .items-header{
      .items-header-title{
        font-size: 18px;
      }
      .items-header-description{
        font-size:14px;
        line-height: 38px;
      }
    }
    .items-bottom-block{
      .bottom-block-info-wrapper{
        .info-block{
          font-size: 14px;
        }
        .items-info-block{
          .items-info{
            font-size: 14px;
          }
        }
      }
      .item-visit-block{
        .date-options-block{
          font-size: 14px;
          span{
            font-size: 14px;
          }
        }
        .item-visit-btn{
          font-size: 14px;
        }
      }
    }
  }
}
