@import "./src/assets/styles/mainVariables";

.homepage-wrapper {
  width: 100%;
  position: relative;
  top: 0;
  //overflow: hidden;

  .first-block {
    height: 100vh;
    overflow: hidden;
    position: relative;

    .mainBlock-wrapper {
      height: 100%;
    }

    .ticker-wrapper {
      position: absolute;
      bottom: 0;
    }
  }

  @media screen and (max-width: 1050px){
    .first-block{
      height: unset;
      .ticker-wrapper{
        position: unset;
      }
    }
  }
}