@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.video-wrapper{
  padding: 60px $mainPadding;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .video-block-header{
    width: 656px;
    .video-block-title{
      color: #000;
      text-align: center;
      font-size: 50px;
      font-family: SpaceMono;
      font-style: normal;
      font-weight: 700;
      line-height: 106.6%;
      text-transform: uppercase;
    }
    .video-block-description{
      color:#8B8B8B;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 10px;
    }
  }
  .video-block{
    height: 500px;
    margin-top: 80px;
    position: relative;
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 1000;
    }
    video{
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 1050px){
    .video-block-header{
      width: 100%;
      .video-block-title{
        color: #000;
        text-align: center;
        font-family: SpaceMono;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -0.6px;
        text-transform: uppercase;
      }
      .video-block-description{
        color:#8B8B8B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 121.9%;
        margin-top: 22px;
      }
    }
    .video-block{
      width: unset!important;
      height:unset;
      margin-top: 46px;
      svg{
        width: 60px;
        height: 60px;
      }
    }
  }
}