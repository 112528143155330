@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.faq-wrapper{
  background-color: #FAFAFA;
  padding: 110px $mainPadding;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  .faq-left-block{
    width: 500px;
    .faq-block-title{
      color: #000;
      font-family: SpaceMono;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 106.1%;
      letter-spacing: -1px;
      text-transform: uppercase;
    }
    .faq-block-description{
      color: #8B8B8B;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 121.9%;
      margin-top: 22px;
    }
    .faq-contact-btn{
      text-decoration: auto;
      width: 260px;
      height: 50px;
      border: unset;
      background-color:$brandColor;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #F0FDF4;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 40px;
      cursor: pointer;
    }
  }
  .faq-right-block{
    width: 46%;
    .ant-collapse-content-box{
      padding: unset!important;
      .faq-information-blocks-description{
        margin-top: 12px;
      }
    }
    .faq-information-wrapper{
      background-color: unset;
      border: unset;
      border-radius: unset;
      .faq-information-blocks{
        padding: 12px 14px;
        background-color: white;
        margin-bottom: 18px;
        border-bottom: unset;
        border-radius: unset;
        .ant-collapse-header{
          display: flex;
          align-items: baseline;
          color: #000;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          padding: unset;
        }
        .ant-collapse-content{
          border-radius: unset;
          border-top: unset;
          color: #8B8B8B;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
    .faq-contact-responsive-btn{
      display: none;
      width: 260px;
      height: 50px;
      border: unset;
      background-color:$brandColor;
      align-items: center;
      justify-content: center;
      color: #F0FDF4;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 40px;
      cursor: pointer;

      @media screen and (max-width: 400px){
        width: 50%;
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding: 60px $mainPadding;
    flex-direction: column;
    .faq-left-block{
      width: 96%;
      .faq-block-title{
        color:#000;
        font-family: SpaceMono;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -0.6px;
        text-transform: uppercase;
      }
      .faq-block-description{
        color: #8B8B8B;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 131.9%;
        margin-top: 22px;
      }
      .faq-contact-btn{
        display: none;
      }
    }
    .faq-right-block{
      width: 100%;
      margin-top: 60px;
      .faq-information-wrapper{
        .faq-information-blocks{
          .ant-collapse-header{
            color: #000;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
          }
          .ant-collapse-content{
            color: #8B8B8B;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
      .faq-contact-responsive-btn{
        display: flex;
      }
    }
  }
}