@import "./src/assets/styles/mainVariables";

.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1002;
  width: 100%;
  height: 100vh;
  overflow: auto;
  transition: all 0.35s cubic-bezier(.39, .575, .565, 1);
  visibility: hidden;
  opacity: 0;
  background-color: rgba(44, 44, 44, 0.7);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  &.expand_on {
    visibility: visible;
    opacity: 1;
  }
  .right-menu{
    width: 70%;
    height: 100vh;
    background-color: rgba(14, 14, 14, 1);
    padding: 30px $mainPadding;
    .close-menu{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: 50px;
      cursor: pointer;
    }

    .menu-block{
      height: 56vh;
        list-style-type:none;
        z-index: 1000;
      padding-inline-start: 30px!important;

        .active {
          .nav-item {
            color: $brandColor;
          }
        }

      .pages-link{
        height: 90%;
        div{
          .nav-item {
            cursor: pointer;
            margin-top: 30px;
            color: #FFF;
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            align-items: center;

            svg{
              margin-left: 4px;
            }
            &:hover {
              color: $brandColor;
              svg{
                path{
                  stroke:$brandColor;
                }
              }
            }
          }
        }
        a{
          text-decoration: unset;
          .nav-item {
            margin-top: 30px;
            color: #FFF;
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            align-items: center;

            svg{
              margin-left: 4px;
            }
            &:hover {
              color: $brandColor;
              svg{
                path{
                  stroke:$brandColor;
                }
              }
            }
          }
        }
      }
      .language-block{
        margin-top: 100px;
        cursor: pointer;
        .nav-item {
          margin-top: 30px;
          color: #FFF;
          font-size: 21px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: flex;
          align-items: center;

          svg{
            margin-left: 4px;
            margin-top: 4px;
          }
          &:hover {
            color: $brandColor;
            svg{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
      }
    }
  }
}