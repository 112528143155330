@import "./src/assets/styles/mainVariables";


.classes-slider-wrapper{
  width: 100%;
  padding-left: 25px;
  .classes-item{
    width: 100%;
    cursor: pointer;
    .classes-img{
      position: relative;
      padding-top: 107.3%;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .classes-info{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F8F8F8;
      padding: 16px 20px;
      .classes-title{
        color:#000;
        font-family: Montserrat;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 48.99px;
        letter-spacing: -0.52px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .classes-icon{
        cursor: pointer;
        svg {
          margin-left: 4px;
          transition: transform 0.2s;
          transform: rotate(0deg);
        }

        &:hover {
          svg {
            transform: rotate(0deg) translate(6px,6px);
          }
        }
      }
    }
  }
}