@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.spa-wrapper{
  width: 100%;
  overflow: hidden;
  .ant-collapse-item-active{
    .ant-collapse-header{
      color: #EE5626!important;
      font-family: Montserrat;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 48.99px;
      letter-spacing: -0.6px;
    }
  }
  .spa-service-block{
    padding: 120px $mainPadding;
    .spa-service-block-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .spa-service-block-title{
        color:#000;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -1px;
        text-transform: uppercase;
        width: 600px;
      }
      .spa-service-block-info{
        width: 380px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .spa-service-block-description{
          color: #8B8B8B;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 131.9%;
        }
        .spa-service-block-work{
          margin-top: 24px;
          color: #8B8B8B;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 131.9%;
          span{
            color: #EE5626;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 131.9%;
          }
        }
        .spa-service-block-price{
          cursor: pointer;
          color: #EE5626;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 131.9%;
          text-decoration-line: underline;
        }
      }
    }
    .spa-service-block-wrapper{
      margin-top: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .spa-information-wrapper{
        width:48%;
        background-color: white;
        border: unset;
        border-radius: unset;
        .spa-information-blocks{
          padding: 20px 0;
          border-bottom: unset;
          border-top: 1.5px solid #E8E8E8;
          &:first-child{
            border-top: unset;
          }
          .ant-collapse-header{
            color:#8B8B8B;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 48.99px;
            letter-spacing: -0.6px;
          }
          .spa-services{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .spa-information{
              .spa-information-blocks-description{
                color: #262626;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 131.9%;
                margin-bottom: 24px;
              }
              .spa-information-blocks-reservation{
                color:#262626;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 131.9%;
                a{
                  color: #EE5626;
                  font-family: Montserrat;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 131.9%;
                  margin-left: 4px;
                }
              }
            }
          }
        }
        .ant-collapse-content{
          border-top: unset;
        }
      }
      .spa-service-img{
        width: 48%;
        .spa-img-block{
          position: relative;
          padding-top: 79.5%;
          img{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }
      }
    }
  }
  .spa-staff-block{
    padding: 120px $mainPadding;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .spa-staff-header{
      .spa-staff-title{
        color: #000;
        text-align: center;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
        text-transform: uppercase;
      }
      .spa-staff-description{
        width: 640px;
        color: #8B8B8B;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-top: 22px;
      }
    }
    .responsive-staff-block{
      width: 100%;
      display: none;
    }
    .spa-staff-wrapper{
      margin-top: 66px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .spa-staff-card-wrapper{
        width: calc(90% / 3);
        margin-left: 40px;
        &:nth-child(3n+2){
          padding-top: 54px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .spa-service-block{
      padding: 100px $mainPadding;
      .spa-service-block-header{
        flex-direction: column;
        .spa-service-block-title{
          width: 100%;
          color:#000;
          font-family: SpaceMono;
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
        }
        .spa-service-block-info{
          width: 100%;
          margin-top: 22px;
          .spa-service-block-description{
            color:#8B8B8B;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 131.9%;
          }
          .spa-service-block-work{
            color:#8B8B8B;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 131.9%;
            span{
              color:#EE5626;
              font-family: Montserrat;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 131.9%;
            }
          }
        }
      }
      .spa-service-block-wrapper{
        margin-top: 82px;
        flex-direction: column-reverse;
        .spa-service-img{
          width: 100%;
          .spa-img-block{
            padding-top: 119%;
          }
        }
        .spa-information-wrapper{
          width: 100%;
        }
      }
    }
    .spa-staff-block{
      .spa-staff-header{
        .spa-staff-title{
          text-align: center;
          font-family: SpaceMono;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
        }
        .spa-staff-description{
          width: 100%;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 22px;
        }
      }
      .responsive-staff-block{
        margin-top: 60px;
        display: flex;
        .workout-staff-card-wrapper{
          width: 100%!important;
        }
      }
      .spa-staff-wrapper{
        display: none;
      }
    }
  }
  @media screen and (max-width: 400px){
    .spa-service-block{
      .spa-service-block-wrapper{
        .spa-information-wrapper{
          .spa-information-blocks{
            .ant-collapse-header{
              color:#8B8B8B;
              font-family: Montserrat;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 48.99px;
              letter-spacing: -0.6px;
            }
            .spa-services{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .spa-information{
                .spa-information-blocks-description{
                  color: #262626;
                  font-family: Montserrat;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 131.9%;
                }
                .spa-information-blocks-reservation{
                  color:#262626;
                  font-family: Montserrat;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 131.9%;
                  margin-top: 24px;
                  a{
                    color: #EE5626;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 131.9%;
                    margin-left: 4px;
                  }
                }
              }
            }
          }
          .ant-collapse-content{
            border-top: unset;
          }
        }
      }
    }
  }
}