@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.membership-wrapper {
  padding: 120px $mainPadding;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .membership-block-header {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;

    .membership-block-title {
      color: #000;
      font-family: SpaceMono;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 126.6%;
      text-transform: uppercase;
    }

    .membership-block-description {
      color: #8B8B8B;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 20px;
    }
  }

  .membership-blocks {
    width: 100%;

    .ant-tabs {
      display: flex;
      align-items: center;

      .ant-tabs-nav {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            .ant-tabs-tab-btn {
              color: #262626;
              font-family: Montserrat;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 48.99px;
              letter-spacing: -0.44px;
            }
          }

          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $brandColor;
              font-family: Montserrat;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 48.99px;
              letter-spacing: -0.44px;
            }
          }

          .ant-tabs-ink-bar {
            background: $brandColor;
          }
        }
      }
    }

    .ant-tabs-content-holder {
      margin-top: 78px;
      width: 100%;
      height: 100%;

    }

    display: flex;

    flex-direction: column;
    align-items: center;

    .membership-block {
      width: 100%;
      max-width: 1300px;
      min-height: 600px;

      .membership-arr-block {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 28px;

        .prev_btn, .next_btn, {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: unset;
          border-radius: 8px;
          background-color: #F3F3F3;
          cursor: pointer;
          path {
            transition: all 0.2s;
          }
          &:hover {
            path {
              stroke: #262626;
            }
          }
        }

        .prev_btn {
          margin-right: 12px;

          path {
            stroke: #8B8B8B;
          }
        }

        .see_all_btn {
          margin-left: 14px;
          height: 32px;
          padding: 0 8px 0 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: unset;
          border-radius: 8px;
          background-color: #F3F3F3;
          cursor: pointer;
          color: #8B8B8B;
          transition: all 0.2s;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;


          path {
            transition: all 0.2s;
          }
          svg{
            width: 20px;
          }
          &:hover {
            color: #262626;

            path {
              stroke: #262626;
            }
          }
        }
      }

      .package-card-slider-wrapper {
        padding: 0 4px;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 100px $mainPadding;

    .membership-block-header {
      width: 100%;
      margin-bottom: 22px;

      .membership-block-title {
        color: #000;
        text-align: center;
        font-family: SpaceMono;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -0.6px;
        text-transform: uppercase;
      }

      .membership-block-description {
        color: #8B8B8B;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 111.9%;
        margin-top: 16px;
      }
    }

    .membership-blocks {
      width: 100%;
      align-items: unset;
      .membership-block {
        .membership-arr-block {
          .see_all_btn {
            margin-right: 0;
          }
        }
      }
      .ant-tabs {
        align-items: unset;

        .ant-tabs-nav {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              .ant-tabs-tab-btn {
                color: #262626;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 48.99px;
                letter-spacing: -0.36px;
              }
            }

            .ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $brandColor;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 48.99px;
                letter-spacing: -0.36px;
              }
            }
          }
        }
      }

      .ant-tabs-content-holder {
        margin-top: 40px;
        width: 100%;
        height: 100%;

        .ant-tabs-tabpane {
          width: 100%;
        }
      }
    }
  }
}
