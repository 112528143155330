@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.events-wrapper {
  padding: 120px $mainPadding;
  background-color: #FAFAFA;

  .events-block-header {
    color: #000;
    text-align: center;
    font-family: SpaceMono;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 104.204%;
    text-transform: uppercase;
    margin-bottom: 70px;
  }

  .ant-tabs {
    display: flex;
    align-items: center;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            color: #262626;
            font-family: Montserrat;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 48.99px;
            letter-spacing: -0.44px;
          }
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: $brandColor;
            font-family: Montserrat;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 48.99px;
            letter-spacing: -0.44px;
          }
        }

        .ant-tabs-ink-bar {
          background: $brandColor;
        }
      }
    }
  }

  .ant-tabs-content-holder {
    margin-top: 78px;
    width: 100%;
    height: 100%;
  }

  .events-block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .events-left-block {
      width: 47%;
      height: 100%;

      .events-left-block-wrapper {
        width: 100%;

        .events-img-left-block {
          position: relative;
          padding-top: 88.7%;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .events-info-left-block {
          width: 100%;
          margin-top: 20px;

          .text-left-block-title {
            color: #262626;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 28.742px;
            letter-spacing: -0.48px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .text-left-block-description {
            height: 60px;
            color: #8B8B8B;
            font-size: 20px;
            font-style: normal;
            font-family: Montserrat;
            font-weight: 500;
            line-height: 28.614px;
            letter-spacing: -0.4px;
            margin-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            p{
              margin: unset;
              color: #8B8B8B;
              font-size: 20px;
              font-style: normal;
              font-family: Montserrat;
              font-weight: 500;
              line-height: 28.614px;
              letter-spacing: -0.4px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              span{
                color: #8B8B8B!important;
                font-size: 20px!important;
                font-style: normal;
                font-family: Montserrat!important;
                font-weight: 500!important;
                line-height: 28.614px;
                letter-spacing: -0.4px;
                a{
                  color: $brandColor!important;
                }
              }
            }
          }
          .item-members{
            margin-top: 12px;
            width: 162px;
            height: 22px;
            overflow: hidden;
            background: #7CD780;
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20.538px;
            letter-spacing: -0.137px;
            text-transform: uppercase;
            display: block;
            align-items: center;
            justify-content: center;

            svg {
              margin-right: 4px;
            }
          }

          .events-post-left-block {
            width: 100%;
            display: flex;
            align-items: center;
            font-family: Montserrat;
            justify-content: space-between;
            margin-top: 28px;

            .post-date-left-block {
              color: #B9B9B9;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 35.768px;
              letter-spacing: -0.4px;
              display: flex;
              align-items: center;

              span {
                color: #B9B9B9;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 28.614px;
                letter-spacing: -0.4px;
                margin-left: 4px;
              }
            }

            .post-view-left-block {
              display: flex;
              align-items: center;
              color: #B9B9B9;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 28.614px;
              letter-spacing: -0.4px;
              svg{
                margin-right: 4px;
              }
            }
          }
        }
      }

      &:hover{
        .events-left-block-wrapper{
          .events-info-left-block{
            .text-left-block-title{
              color:$brandColor;
            }
          }
        }
      }
    }

    .events-right-block {
      width: 47%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .event-see-more {
        display: flex;
        align-items: center;
        color: $brandColor;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 110.377%;
        cursor: pointer;
        text-decoration: auto;

        svg {
          margin-left: 4px;
          transition: transform 0.2s;
          transform: rotate(0deg);
        }

        &:hover {
          svg {
            transform: rotate(0deg) translateX(6px);
          }
        }
      }
    }
  }


  @media screen and (max-width: 1050px) {
    padding: 60px $mainPadding 100px;
    .events-block-header {
      color: #000;
      text-align: center;
      font-family: SpaceMono;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 106.1%;
      letter-spacing: -0.6px;
      text-transform: uppercase;
      margin-bottom: 22px;
    }
    .ant-tabs {
      .ant-tabs-nav {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            .ant-tabs-tab-btn {
              color: #262626;
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 48.99px;
              letter-spacing: -0.36px;
            }
          }

          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $brandColor;
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 48.99px;
              letter-spacing: -0.36px;
            }
          }
        }
      }
    }
    .ant-tabs-content-holder{
      margin-top: 52px;
    }
    .events-block {
      flex-direction: column;

      .ant-tabs-content-holder {
        margin-top: 52px;
      }
        .events-left-block {
          display: none;
        }

        .events-right-block {
          width: 100%;

          .event-see-more {
            display: flex;
            align-items: center;
            color: $brandColor;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 110.377%;
            cursor: pointer;
          }
        }
      }
  }
}
