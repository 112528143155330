@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.fitness-wrapper{
  overflow: hidden;
  .workout-block{
    padding: 120px $mainPadding;
    .workout-header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .workout-title{
        width: 550px;
        color: #000;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.6%;
        text-transform: uppercase;
        font-family:SpaceMono;
      }
      .workout-description{
        width: 386px;
        color: #8B8B8B;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }
    .workout-card-block{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 80px;
    }
  }
  .classes-block{
    width: 100%;
    padding-bottom:140px ;
    .classes-header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .classes-title{
        color:#000;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
        text-transform: uppercase;
        text-align: center;
      }
      .classes-description{
        width: 50%;
        color:#8B8B8B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-top: 22px;
      }
    }
    .classes-wrapper{
      margin-top: 78px;
      .ant-tabs{
        display: flex;
        align-items: center;
        .ant-tabs-nav{
          .ant-tabs-nav-list{
            .ant-tabs-tab{
              .ant-tabs-tab-btn{
                color:#262626;
                font-family: Montserrat;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 48.99px;
                letter-spacing: -0.44px;
              }
            }
            .ant-tabs-tab-active{
              .ant-tabs-tab-btn{
                color:$brandColor;
              }
            }
            .ant-tabs-ink-bar{
              background:$brandColor;
            }
          }
        }
      }
      .ant-tabs-content-holder{
        margin-top: 78px;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .workout-block{
      padding: 100px $mainPadding;
      .workout-header{
        flex-direction: column;
        .workout-title{
          width: 100%;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
        }
        .workout-description{
          width: 100%;
          color: #8B8B8B;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 22px;
        }
      }
      .workout-card-block{
        flex-direction: column;
        margin-top: 50px;
      }
    }
    .classes-block{
      .classes-header{
        width: unset;
        padding: 0 $mainPadding;
        .classes-title{
          color:#000;
          text-align: center;
          font-family: SpaceMono;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
        }
        .classes-description{
          width: 100%;
          color:#8B8B8B;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 22px;
        }
      }
      .classes-wrapper{
        margin-top: 60px;
        .ant-tabs-content-holder{
          margin-top: 40px!important;
        }
        .ant-tabs{
          align-items: unset;
          .ant-tabs-nav{
            .ant-tabs-nav-list{
              .ant-tabs-tab{
                &:first-child{
                  margin-left: 20px;
                }
                &:nth-child(6){
                  margin-right: 20px!important;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px){
    .classes-block{
      .classes-wrapper{
        .ant-tabs{
          align-items: unset;
          .ant-tabs-nav{
            .ant-tabs-nav-list{
              .ant-tabs-tab{
                .ant-tabs-tab-btn{
                  font-size: 16px;
                }
              }
              .ant-tabs-tab-active{
                .ant-tabs-tab-btn{
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}