@import "./src/assets/styles/mainVariables";

.notFound-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding-top:40px;
  height: 100vh;
  svg{
    width: 180px;
    height: 180px;
    g{
      fill:$brandColor;
    }
  }
  span{
    color:$brandColor;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 126.6%;
    text-transform: uppercase;
  }
}