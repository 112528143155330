@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.app-block-wrapper{
  padding: 40px $mainPadding 0;
  background: linear-gradient(90deg, rgba(0,0,0,1) 42%, rgba(238,86,38,1) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .app-left-block{
    width: 50%;
    .app-block-title{
      color: #FFF;
      font-family: SpaceMono;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 105.203%;
      text-transform: uppercase;
    }
    .app-block-description{
      color:#8B8B8B;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 20px;
    }
    .app-download-block{
      display: flex;
      margin-top: 40px;
      svg{
        &:first-child{
          margin-right: 12px;
        }
      }
    }
  }
  .app-right-block{
    width: 46%;
    margin-bottom: -4px;
    .app-img-wrapper{
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 1050px){
    background: linear-gradient( rgba(0,0,0,1) 48%, rgba(238,86,38,1) 100%);
    flex-direction: column;
    padding: 60px $mainPadding 0;
    .app-left-block{
      width: 90%;
      .app-block-title{
        color: #FFF;
        text-align: center;
        font-family: SpaceMono;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -0.6px;
        text-transform: uppercase;
      }
      .app-block-description{
        color: #8B8B8B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-top: 22px;
      }
      .app-download-block{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a{
          width: 48%;
          svg{
            width: 100%;
          }
        }
      }
    }
    .app-right-block{
      width: 100%;
      margin-top: 80px;
    }
  }
}