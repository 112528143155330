@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.about-wrapper {
  overflow: hidden;

  .about-info-block {
    padding: 156px $mainPadding 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info-text-block {
      width: calc(50% - 20px);

      .about-info-title {
        max-width: 646px;
        color: #000;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.6%;
        text-transform: uppercase;
      }

      .about-info-description {
        width: 560px;
        color: #262626;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-top: 24px;
      }

      .about-info-btn {
        text-decoration: auto!important;
        width: 260px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $brandColor;
        color: #F0FDF4;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-top: 40px;
        border: unset;
        cursor: pointer;
      }
      .about-pdf-info-btn{
        color:$brandColor;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 131.9%;
        text-decoration-line: underline;
        margin-top: 24px;
        cursor: pointer;
      }
    }

    .info-img-block {
      width: 48%;

      .img-wrapper {
        padding-top: 85%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .about-advantages-block {
    padding: 0 $mainPadding 120px;

    .about-advantages-title {
      color: #000;
      font-family: SpaceMono;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 126.6%;
      text-transform: uppercase;
      text-align: center;
    }

    .about-advantages {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 22px;
      margin-top: 80px;

      .advantages-item {
        width: calc(100% / 3 - 92px);
        background-color: #F9F9F9;
        padding: 40px 38px;

        .advantages-item-title {
          color: #000;
          font-family: Montserrat;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .advantages-item-descriptions {
          color: #262626;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 24px;
        }

        .advantages-item-icon {
          margin-top: 56px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          img{
            width: 80px;
            height: 100%;
          }
        }

        &:first-child {
          width: calc(100% / 2 - 88px);
        }

        &:nth-child(2) {
          width: calc(100% / 2 - 88px);
        }
      }
    }
  }

  .about-video-block {
    padding: 60px $mainPadding 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--pale-grey, #FAFAFA);
    margin: 120px 0;

    .about-video-title {
      color: #000;
      font-family: SpaceMono;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 126.6%;
      text-transform: uppercase;
      text-align: center;
      width: 658px;
    }

    .about-video-description {
      color: #8B8B8B;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin-top: 22px;
      width: 640px;
    }

    .video-block{
      height: 500px;
      margin-top: 80px;
      position: relative;
      svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1000;
      }
      video{
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .about-info-block{
      padding: 76px $mainPadding;
      flex-direction: column-reverse;
      .info-text-block{
        width: 100%;
        margin-top: 50px;
        .about-info-title{
          width: 100%;
          font-family: SpaceMono;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
          text-transform: uppercase;
        }
        .about-info-description{
          width: 100%;
          color:#262626;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 22px;
        }
        .about-info-btn{
          margin-top: 60px;
        }
        .about-pdf-info-btn{
          color: #EE5626;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.9%;
          text-decoration-line: underline;
          margin-top: 22px;
        }
      }
      .info-img-block{
        width: 100%;
      }
    }
    .about-advantages-block{
      padding: 0 $mainPadding 100px;
      .about-advantages-title{
        color: #000;
        font-family: SpaceMono;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -0.6px;
        text-transform: uppercase;
      }
      .about-advantages{
        flex-wrap: unset;
        flex-direction: column;
        margin-top: 40px;

        .advantages-item {
          width: unset;

          .advantages-item-icon {
            margin-top: 98px;
          }

          &:first-child {
            width:unset;
          }

          &:nth-child(2) {
            width:unset;
          }
        }
      }
    }
    .about-video-block{
      margin: 78px 0 100px 0;
      .about-video-title{
        width: 100%;
        text-align: center;
        font-family: SpaceMono;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -0.6px;
      }
      .about-video-description{
        width: 100%;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 131.9%;
      }
      .video-block{
        width: 100%;
        margin-top: 46px;
        height: 340px;
        background-color:black;
        svg{
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}