@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";


.partnersCard{
  padding: 12px 12px 16px 12px;
  border-radius: 12px;
  background: #FFFFFF;
  border: 1.5px solid #E8E8E8;
  display: block;
  min-height: 402px;

  .partnersCard_img{
    width: 100%;
    height: 160px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
  .info_block{
    margin-top: 16px;
    .info_title{
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #262626;
      margin-bottom: 8px;
    }
    .info_description{
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #262626;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      height: 120px;

      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #8B8B8B;
        margin-right: 3px;
      }
    }
    .more-btn{
      cursor: pointer;
      margin: 12px 0 8px 0;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 17.07px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      display: flex;
      align-items: center;
      color: #262626;
      svg{
        margin-left: 6px;
        transition: transform .2s;
        transform: rotate(0deg);
      }
    }
  }

  &:hover{
    .info_title{
      color:$brandColor;
    }
    .info_block{
      .more-btn{
        svg{
          transform: rotate(0deg) translateX(6px);
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    //.partnersCard_img{
    //  height: 234px;
    //}
    .info_block{
      .info_title{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
      }
      .info_description{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        -webkit-line-clamp: 3;
        span{
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .more-btn{
        margin-top: 32px;
      }
    }
  }
}