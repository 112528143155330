@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.article-detail-wrapper{
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .article-detail-header{
    width: 100%;
    padding-top: 124px;
    background-color: black;
    .article-detail-img-block{
      position: relative;
      padding-top: 33.8%;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .article-detail-info-block{
    width: 70%;
    padding: 80px 0 120px 0;
    .article-detail-title{
      color: #292929;
      font-family: Montserrat;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 129.1%;
      letter-spacing: -0.2px;
    }
    .article-detail-description{
      color: #292929;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 40px;

      p,div,h1{
        margin: unset;
        a{
          color: $brandColor!important;
        }
        span{
          color: #292929!important;
          font-family: Montserrat!important;
          font-size: 20px!important;
          font-style: normal!important;
          font-weight: 400!important;
          line-height: 30px;
          margin-top: 40px;
        }
      }
    }
    .article-detail-info-bottom{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #B9B9B9;
      padding-top: 20px;
      margin-top: 50px;
      .article-detail-date{
        color:#B9B9B9;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 35.768px;
        letter-spacing: -0.4px;
        span{
          color:#B9B9B9;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28.614px;
          letter-spacing: -0.4px;
          margin-left: 4px;
        }
      }
      .article-detail-view{
        color:#B9B9B9;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.614px;
        letter-spacing: -0.4px;
        display: flex;
        align-items: center;
        svg{
          margin-right: 4px;
        }
      }
    }
  }
  .article-detail-article-wrapper{
    padding: 0 $mainPadding 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .article-detail-article-header{
      width: 652px;
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .article-detail-article-title{
        color:#000;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
        text-transform: uppercase;
      }
      .article-detail-article-description{
        margin-top: 22px;
        color:#8B8B8B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }
    .article-detail-article-block{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .article-detail-article{
        width:calc(100% / 3 - 38px);
      }
    }
  }

  @media screen and (max-width: 1050px){
    .article-detail-header{
      padding-top: 120px;
      .article-detail-img-block{
        padding-top: 62.5%;
      }
    }
    .article-detail-info-block{
      width:unset;
      padding: 80px $mainPadding;
      .article-detail-title{
        font-size: 28px!important;
        font-style: normal;
        font-weight: 700;
        line-height: 129.1%;
        letter-spacing: -0.15px;
      }
      .article-detail-description{
        color: #292929;
        font-family: Montserrat;
        font-size: 18px!important;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        p{
          span{
            font-size: 18px!important;
          }
        }
      }
      .article-detail-info-bottom{
        .article-detail-date{
          font-size: 17px;
          span{
            font-size: 17px;
          }
        }
        .article-detail-view{
          font-size: 17px;
          svg{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .article-detail-article-wrapper{
      .article-detail-article-header{
        width: 100%;
        margin-bottom: unset;
        .article-detail-article-title{
          color:#000;
          text-align: center;
          font-family: SpaceMono;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
          text-transform: uppercase;
        }
        .article-detail-article-description{
          color:#8B8B8B;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
        }
      }
      .article-detail-article-block{
        flex-direction: column;
        .article-detail-article{
          width: 100%;
          margin-top: 62px;
        }
      }
    }
  }
}