@import "./src/assets/styles/mainVariables";


.custom-modal{
  :where(.css-dev-only-do-not-override-14wwjjs).ant-modal .ant-modal-content{
    background-color: black!important;
    background-clip: unset!important;
    box-shadow: unset!important;
  }
  .ant-modal{
    width: 70%!important;
    .ant-modal-content{
      padding: unset;
      border-radius: unset;
      .ant-modal-close{
        width: 36px;
        height: 36px;
        svg{
          width: 36px;
          height: 36px;
        }
        &:hover{
          svg{
            path{
              fill: #0e0e0e;
            }
          }
        }
      }
    }
  }
}
.slider-modal-wrapper{
  width: 100%;
  .modal-slider-wrapper{
    width: 100%;
    position: relative;
    padding-bottom: 59.2%;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      //border-radius: 20px;
    }
  }
  .next-btn{
    border: unset;
    background: #262626;
    cursor: pointer;
    position: absolute;
    padding: 6px 7px;
    top: 46%;
    right: -6%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    svg{
      path{
        fill: white;
      }
      &:hover{
        path{
          fill: $brandColor;
        }
      }
    }
  }
  .prev-btn{
    border: unset;
    background: #262626;
    cursor: pointer;
    position: absolute;
    padding: 6px 7px;
    z-index: 6;
    top: 46%;
    left: -6%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      path{
        fill: white;
      }
      &:hover{
        path{
          fill: $brandColor;
        }
      }
    }
  }

}

@media screen and (max-width: 1050px){
  .custom-modal{
    .ant-modal{
      width: 86%!important;
    }
  }
  .slider-modal-wrapper{
    .next-btn{
      width: 30px;
      height: 30px;
      right: -8%;
    }
    .prev-btn{
      width: 30px;
      height: 30px;
      left: -8%;
    }
  }
}