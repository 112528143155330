@import "./src/assets/styles/mainVariables";

.header-wrapper {
  -webkit-user-select: none !important;
  user-select: none !important;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;

  a {
    .orange-logo {
      width: 114px;
      padding: 40px 0 0 $mainPadding;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .nav-menu {
    padding: 40px $mainPadding 0 0;
    display: flex;
    align-items: center;
    list-style-type: none;
    z-index: 1000;

    .link{
      cursor: pointer;
      .nav-item {
        cursor: pointer;
        margin-left: 26px;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        color: white;
        display: flex;
        align-items: center;

        svg{
          margin-left: 4px;
        }
        &:hover {
          color: $brandColor;
          svg{
            path{
              stroke:$brandColor;
            }
          }
        }
      }
    }
    .active {
      .nav-item {
        color: $brandColor;
      }
    }

    a {
      text-decoration: unset;

      .nav-item {
        margin-left: 42px;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        color: white;
        display: flex;
        align-items: center;

        svg {
          margin-left: 4px;
        }

        &:hover {
          color: $brandColor;

          svg {
            path {
              stroke: $brandColor;
            }
          }
        }
      }
    }

    .language-block {
      margin-left: 120px;
      cursor: pointer;

        .selected-language{
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 110.377%;
          display: flex;
          align-items: center;

          svg {
            margin-left: 4px;
          }
          &:hover {
            color: $brandColor;

            svg {
              path {
                stroke: $brandColor;
              }
            }
          }
        }
    }

    .header_burger {
      display: none;
    }
  }

  @media screen and (max-width: 1050px) {
    .language-block {
      display: none;
    }
    a {
      .orange-logo {
        padding: 40px 0 0 $mainPadding;

        svg {
          width: 86px;
          height: 48px;
        }
      }
    }
    .nav-menu {
      .link{
        display: none;
      }
      a {
        .nav-item {
          display: none;
        }
      }

      .header_burger {
        display: flex;
        cursor: pointer;
      }
    }
  }
}
