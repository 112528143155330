@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.events-blocks-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  text-decoration: auto !important;

  .events-img-block {
    width: 30%;

    .img-wrapper {
      position: relative;
      padding-top: 94.4%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .events-info-block {
    width: 68%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .events-text-block {
      .text-block-title {
        color: #262626;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32.191px;
        letter-spacing: -0.48px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .text-block-description {
        height: 56px;
        color: #8B8B8B;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28.614px;
        letter-spacing: -0.4px;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        span {
          overflow: hidden!important;
          text-overflow: ellipsis!important;
          display: -webkit-box!important;
          -webkit-line-clamp: 2!important;
          -webkit-box-orient: vertical!important;
          color: #8B8B8B!important;
          font-family: Montserrat!important;
          font-size: 20px!important;
          font-style: normal!important;
          font-weight: 500!important;
          line-height: 26.614px;
          letter-spacing: -0.4px;
        }

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #8B8B8B !important;
          font-family: Montserrat!important;
          font-size: 20px !important;
          font-style: normal!important;
          font-weight: 500!important;
          line-height: 28.614px;
          letter-spacing: -0.4px;
        }

        p {
          margin: unset;

          br {
            display: none;
          }

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #8B8B8B !important;
            font-family: Montserrat!important;
            font-size: 20px !important;
            font-style: normal!important;
            font-weight: 500!important;
            line-height: 28.614px;
            letter-spacing: -0.4px;
          }
        }
      }

      .events-card-members {
        margin-top: 12px;
        width: 162px;
        height: 22px;
        overflow: hidden;
        background: #7CD780;
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.538px;
        letter-spacing: -0.137px;
        text-transform: uppercase;
        display: block;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 4px;
        }
      }
    }

    .events-post-block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: Montserrat;
      margin-top: 38px;

      .post-date-block {
        color: #B9B9B9;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 35.768px;
        letter-spacing: -0.4px;
        display: flex;
        align-items: center;

        span {
          color: #B9B9B9;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28.614px;
          letter-spacing: -0.4px;
          margin-left: 4px;
        }
      }

      .post-view-block {
        display: flex;
        align-items: center;
        color: #B9B9B9;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.614px;
        letter-spacing: -0.4px;

        svg {
          margin-right: 4px;
        }
      }
    }
  }

  &:hover {
    .events-info-block {
      .events-text-block {
        .text-block-title {
          color: $brandColor;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    width: 100%;

    .events-img-block {
      width: 36%;

      .img-wrapper {
        padding-top: 100%;
      }
    }

    .events-info-block {
      width: 62%;

      .events-text-block {
        .text-block-title {
          color: #262626;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 23.217px;
          letter-spacing: -0.36px;
        }

        .text-block-description {
          height: 44px;
          color: #8B8B8B;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20.638px;
          letter-spacing: -0.32px;
          margin-top: 6px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          span {
            color: #8B8B8B;
            font-family: Montserrat;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 500;
            line-height: 20.638px;
            letter-spacing: -0.32px;
          }

          p {
            span {
              color: #8B8B8B;
              font-family: Montserrat;
              font-size: 14px !important;
              font-style: normal;
              font-weight: 500;
              line-height: 20.638px;
              letter-spacing: -0.32px;
            }
          }
        }
      }

      .events-post-block {
        margin-top: 14px;

        .post-date-block {
          color: #B9B9B9;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 25.797px;
          letter-spacing: -0.32px;
          display: flex;
          align-items: unset;
          flex-direction: column;

          span {
            color: #B9B9B9;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20.638px;
            letter-spacing: -0.32px;
          }
        }

        .post-view-block {
          display: flex;
          align-items: center;
          color: #B9B9B9;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20.638px;
          letter-spacing: -0.32px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}