@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";


.cafe-wrapper{
  overflow: hidden;
  .cafe-manu-block{
    padding: 120px $mainPadding;
    .menu-header{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .menu-title{
        width: 580px;
        color: #000;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -1px;
        text-transform: uppercase;
      }
      .menu-block{
        width: 378px;
        .menu-description{
          color:#8B8B8B;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 131.9%;
        }
        .menu-pdf{
          color:$brandColor;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.9%;
          text-decoration-line: underline;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
    .cafe-menu-images{
      width: 100%;
      margin-top: 112px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      .menu-images-item{
        width:calc(97% / 3);
        .menu-images-content{
          position: relative;
          padding-top: 86.5%;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .more-content{
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            .text-count{
              color: #FFF;
              font-family: Montserrat;
              font-size: 32px;
              font-style: normal;
              font-weight: 500;
              line-height: 130%;
            }
          }
        }
        &:first-child{
          width: calc(98% - 96% / 3);
          .menu-images-content{
            position: relative;
            padding-top: 42.4%;
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .more-content{
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
              display: flex;
              align-items: center;
              justify-content: center;
              .text-count{
                color: #FFF;
                font-family: Montserrat;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .cafe-manu-block{
      padding: 100px $mainPadding;
      .menu-header{
        flex-direction: column;
        .menu-title{
          width: 100%;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
        }
        .menu-block{
          width: 100%;
          margin-top: 22px;
          .menu-description{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 131.9%;
          }
          .menu-pdf{
            color: #EE5626;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 131.9%;
            text-decoration-line: underline;
            margin-top: 22px;
          }
        }
      }
      .cafe-menu-images{
        margin-top: 60px;
        .menu-images-item{
          width:29.6%;
          .menu-images-content{
            padding-top: 114%;
            .more-content{
              .text-count{
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
              }
            }
          }
          &:first-child{
            width: 100%;
            .menu-images-content{
              position: relative;
              padding-top: 81.8%;
            }
          }
        }
      }
    }
  }
}