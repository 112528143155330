@use "/src/assets/styles/mainVariables";

.copy_wrapper {
  position: relative;
  z-index: 100;
  user-select: none;
  margin-top: 10px;

  .copy_block {
    height: 34px;
    background: #F7F7F7;
    border-radius: 4px;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;

    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin-left: 4px;
      color: mainVariables.$brandSecColor;
    }
    svg{
      path{
        transition: all .2s;
      }
    }

    &:hover {
      background: #e7e6e6;
    }

    &.copied {
      background: #00D376;
      span {
        color: white;
      }
      svg{
        path{
          //fill: #00D376;
          stroke: white;
        }
      }
    }
  }
}
