@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.workout-wrapper{
  width: 100%;
  overflow: hidden;
  .workout-top-block{
    height: 100vh;
    overflow: hidden;
    position: relative;
    .workout-page-header{
      width: 100%;
      height: 100%;
      position: relative;
      .workout-page-img{
        position: relative;
        padding-top: 61.5%;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .workout-page-info{
        position: absolute;
        bottom: 160px;
        padding: 0 $mainPadding;
        .workout-page-title{
          font-family: SpaceMono;
          font-size: 110px;
          font-style: italic;
          font-weight: 700;
          line-height: 126.6%;
          text-transform: uppercase;
          color: transparent;
          -webkit-text-stroke: 1px white;
        }
        .workout-page-description{
          width: 50%;
          color: #8B8B8B;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 131.9%;
          margin-top: 8px;
        }
      }
      .workout-gradient-block{
        position: absolute;
        bottom: 0;
        height: 200px;
        width: 100%;
        background: linear-gradient(0deg, #000 34.96%, rgba(0, 0, 0, 0.00) 100%);

      }
    }
    .ticker-wrapper {
      position: absolute;
      bottom: 0;
    }
  }
  .workout-staff-block{
    padding: 120px $mainPadding;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .workout-staff-header{
      .workout-staff-title{
        color: #000;
        text-align: center;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
        text-transform: uppercase;
      }
      .workout-staff-description{
        width: 640px;
        color: #8B8B8B;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-top: 22px;
      }
    }
    .responsive-staff-block{
      width: 100%;
      display: none;
    }
    .workout-staff-wrapper{
      margin-top: 66px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .workout-staff-card-wrapper{
        cursor: pointer;
        width: calc(90% / 3);
        margin-left: 40px;
        &:nth-child(3n+2){
          padding-top: 54px;
        }
      }
    }
  }
  .workout-gallery-block{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 $mainPadding 120px;
    .workout-gallery-title{
      color: #000;
      font-family: SpaceMono;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 106.1%;
      letter-spacing: -1px;
      text-transform: uppercase;
    }
    .gallery-wrapper{
      width: 100%;
      margin-top: 80px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px;
      .media-item{
        width:calc(97% / 3);
        .media-item-content{
          position: relative;
          padding-top: 86.5%;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .more-content{
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            .text-count{
              color: #FFF;
              font-family: Montserrat;
              font-size: 32px;
              font-style: normal;
              font-weight: 500;
              line-height: 130%;
            }
          }
        }
        &:first-child{
          width: calc(98% - 96% / 3);
          .media-item-content{
            position: relative;
            padding-top: 42.4%;
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .more-content{
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
              display: flex;
              align-items: center;
              justify-content: center;
              .text-count{
                color: #FFF;
                font-family: Montserrat;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .workout-top-block{
      height: unset;
      .workout-page-header{
        .workout-page-img{
          padding-top: 128.7%;
        }
        .workout-page-info{
          bottom: 82px;
          z-index: 900;
          .workout-page-title{
            font-family: SpaceMono;
            font-size: 48px;
            font-style: italic;
            font-weight: 700;
            line-height: 126.6%;
            text-transform: uppercase;
          }
          .workout-page-description{
            margin-top: 20px;
            color:#8B8B8B;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 131.9%;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .workout-staff-block{
      padding: 100px $mainPadding;
      .workout-staff-header{
        width: 100%;
        .workout-staff-title{
          text-align: center;
          font-family: SpaceMono;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
        }
        .workout-staff-description{
          width: 100%!important;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 22px;
        }
      }
      .workout-staff-wrapper{
        display: none;
      }
      .responsive-staff-block{
        margin-top: 60px;
        display: unset;
        .workout-staff-card-wrapper{
          width: 100%!important;
        }
      }
    }
    .workout-gallery-block{
      padding: 0 $mainPadding 100px;
      .workout-gallery-title{
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.1%;
        letter-spacing: -0.6px;
      }
      .gallery-wrapper{
        margin-top: 40px;
        .media-item{
          width: 29.6%;
          .media-item-content{
            padding-top: 114%;
            .more-content{
              .text-count{
                color:#FFF;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
              }
            }
          }
          &:first-child{
            width:100%;
            .media-item-content{
              padding-top:81.8%;
            }
          }
        }
      }
    }
  }
}
