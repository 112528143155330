@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.privacyPolice-wrapper{
  overflow: hidden;
  .privacyPolice{
    padding: 120px $mainPadding;
    a{
      color: $brandColor!important;
    }
  }
}