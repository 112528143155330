@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";


.partnersDetail{
  background-color: #F7F6F5;
  min-height: 720px;

  .top {
    width: 100%;
    height: 124px;
    background-color: black;
  }

  .header-block {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;

    .content{
      padding: 18px $mainPadding;
      display: flex;
      align-items: center;
      background-color: white;
      border: 1px solid #F3F3F3;
      a{
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #8B8B8B;
        margin-right: 4px;
        transition: color 0.5s;
        &:hover{
          color: #262626;
        }
      }
      p{
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #262626;
      }
    }
  }
  .partnersDetail_wrapper{
    padding: 24px $mainPadding;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .partnersDetail_left{
       width: 360px;
       padding: 20px 20px 24px 20px;
       border-radius: 8px;
       background: #FFFFFF;
       border: 1px solid #E8E8E8;
       position: sticky;
       top: 80px;

       .partnersDetail_img{
         width: 100%;
         height: 180px;
         img{
           width: 100%;
           height: 100%;
           border-radius: 6px;
           object-fit: cover;
         }
       }
       .partnersDetail_title{
         margin-top: 24px;
         font-family: Montserrat;
         font-size: 24px;
         font-weight: 500;
         line-height: 24px;
         letter-spacing: 0.01em;
         text-align: left;
         text-underline-position: from-font;
         text-decoration-skip-ink: none;
         color: #262626;
       }
       .partnersDetail_sub{
         margin-top: 16px;
         font-family: Montserrat;
         font-size: 13px;
         font-weight: 400;
         line-height: 24px;
         text-align: left;
         text-underline-position: from-font;
         text-decoration-skip-ink: none;
         color: #262626;
       }
       .partnersDetail_socials{
         display: flex;
         align-items: center;
         margin: 24px 0;
         a{
           margin-right: 20px;
           transition: all 0.5s;
           &:hover{
             svg{
               transition: all 0.5s;
               path{
                 fill: #000000;
               }
             }
           }
         }
       }
       .partnersDetail_addresses{
         display: flex;
         flex-direction: column;
         border-top: 1px solid #E8E8E8;
         padding-top: 24px;
         a, span{
           font-family: Montserrat;
           font-size: 14px;
           font-weight: 300;
           line-height: 18px;
           letter-spacing: -0.02em;
           text-align: left;
           text-underline-position: from-font;
           text-decoration-skip-ink: none;
           color: #8B8B8B;
           margin-bottom: 10px;
           transition: color 0.5s;
           &:hover{
             color: #2C4DA8;
           }
         }
       }
       .partnersDetail_call{
         position: relative;
         width: 100%;
         cursor: pointer;
         z-index: 1;
         .partnersDetail_phone{
           margin-top: 14px;
           width:100%;
           height:42px;
           background-color: #262626;
           color: white;
           display: flex;
           align-items: center;
           justify-content: center;
           cursor: pointer;
           transition: background-color 0.5s;
           &:hover{
             cursor: pointer;
             background-color: black;
           }
         }
         .phones{
           width: 100%;
           position: absolute;
           top: 42px;
           padding: 4px 8px;
           display: flex;
           flex-direction: column;
           background-color: white;
           box-shadow: 0 3px 12px 0 #0000001A;
           .close_phones{
             position: absolute;
             right: 8px;
             top: 12px;
             cursor: pointer;
             transition: all 3s;
             svg{
               width: 24px;
               height: 24px;
               path{
                 stroke: #8B8B8B;
               }
             }
             &:hover{
               svg{
                 path{
                   stroke: #000000;
                 }
               }
             }
           }
           .phone_item{
             font-family: Montserrat;
             font-size: 13px;
             font-weight: 400;
             line-height: 24px;
             text-align: left;
             text-underline-position: from-font;
             text-decoration-skip-ink: none;
             color: #262626;
             margin: 8px 0;
             transition: color 0.5s;
             &:hover{
               color: #8B8B8B;
             }
           }
         }
       }
     }
    .partnersDetail_right{
      width: calc(100% - 380px);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      .partnersDetail_img_item{
        width: calc(100% / 2 - 10px);
        height: 432px;
        position: relative;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 6px;
        }
        .partnersDetail_content{
          //min-height: calc(432px - 52px);
          border-radius: 6px;
          border: 2px solid #E8E8E8;
          background-color: #F7F7F7;
          flex-direction: column;
          justify-content: space-between;
          padding: 24px;
          display: flex;
          height: 0;
          transition: .5s ease;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          z-index: 11;
          overflow: hidden;
          transform: translateY(0);
          .partnersDetail_content_info{
            .sale{
              font-family: Montserrat;
              font-size: 22px;
              font-weight: 500;
              line-height: 28.4px;
              letter-spacing: -0.005em;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #292929;
            }
            .description{
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              margin-top: 14px;
              color: #292929;
              p > span{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                margin-top: 14px;
                color: #292929;
              }
            }
          }
          .link_btn{
            margin-top: 14px;
            width:100%;
            height:42px;
            background-color: #262626;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &:hover{
          .partnersDetail_content{
            top: 0;
            height: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .top {
      height: 110px;
    }
    .header-block{
      .content{
       display: none;
      }
    }
    .partnersDetail_wrapper{
      flex-direction: column;
      padding: 20px $mainPadding;
      .partnersDetail_left{
        width: 100%;
        position: relative;
        top: unset;
      }
      .partnersDetail_right{
        width: 100%;
        gap: unset;
        margin-top: 28px;
        .partnersDetail_img_item{
          width: 100%;
          height: unset;
          aspect-ratio: 1 / 1;
          margin-bottom: 24px;
        }
        .partnersDetail_content{
          //width: calc(100% - 62px);
          //min-height: calc(432px - 52px);
        }
      }
    }
  }
}