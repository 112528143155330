@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.news-card-wrapper {
  width: 100%;
  overflow: hidden;
  text-decoration: auto !important;

  .news-card-img-wrapper {
    position: relative;
    padding-top: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .news-card-info-wrapper {
    margin-top: 18px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .news-card-date {
      display: flex;
      align-items: center;
      span {
        color: $brandColor;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.36px;
        text-transform: capitalize;
      }
      .separator {
        margin: 0 8px;
      }
      @media all and ( max-width: 1100px){
        span {
          font-size: 16px;
        }
      }
    }

    .news-card-title {
      margin-top: 8px;
      color: #262626;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32.191px;
      letter-spacing: -0.48px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .news-card-description {
      color: #8B8B8B;
      font-size: 20px;
      height: 60px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 500;
      line-height: 28.614px;
      letter-spacing: -0.4px;
      margin-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      div {
        span {
          color: #8B8B8B !important;
          font-size: 20px !important;
          font-style: normal !important;
          font-family: Montserrat !important;
          font-weight: 500 !important;
          line-height: 28.614px;
          letter-spacing: -0.4px;
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      p {
        margin: unset;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        a {
          color: $brandColor !important;
        }

        span {
          color: #8B8B8B !important;
          font-size: 20px !important;
          font-style: normal !important;
          font-family: Montserrat !important;
          font-weight: 500 !important;
          line-height: 28.614px;
          letter-spacing: -0.4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .news-card-members {
      margin-top: 12px;
      width: 162px;
      height: 22px;
      overflow: hidden;
      background: #7CD780;
      color: #FFF;
      text-align: center;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.538px;
      letter-spacing: -0.137px;
      text-transform: uppercase;
      display: block;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 4px;
      }
    }

    .news-card-bottom-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      .news-card-created {
        color: #B9B9B9;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 35.768px;
        letter-spacing: -0.4px;

        span {
          color: #B9B9B9;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28.614px;
          letter-spacing: -0.4px;
          margin-left: 4px;
        }
      }

      .news-card-see {
        color: #B9B9B9;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.614px;
        letter-spacing: -0.4px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }
    }
  }

  &:hover {
    .news-card-info-wrapper {
      .news-card-title {
        color: $brandColor;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .news-card-info-wrapper {
      .news-card-description {
        height: 62px;
      }

      .news-card-bottom-info {
        .news-card-created {
          font-size: 18px;

          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}
