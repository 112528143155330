@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";


.contact-wrapper{
  overflow: hidden;
  .contact-block{
    padding: 100px $mainPadding 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .contact-us{
      width: calc(50% - 100px);
      .contact-us-title{
        color: #000;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
        text-transform: uppercase;
      }
      .contact-us-description{
        color:#262626;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-top: 22px;
      }
      .contact-input-wrapper{
        width: calc(100% - 32px);
        margin-top: 120px;
        .top-input-block,.mid-input-block{
          width: calc(100% - 32px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          .custom-input-group{
            width: calc(48% - 24px);
            input{
              width: 100%;
              height: 50px;
              color:#262626;
              font-family: Montserrat;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.32px;
            }
          }
        }
        .custom-input-group{
          textarea{
            width: calc(100% - 32px);
            height: 88px;
            padding-top: 12px;
            color:#262626;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.32px;
          }
        }
        .btn-block{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .sent-email{
            width: 48%;
            height: 50px;
            margin-top: 40px;
            background-color:$brandColor;
            display: flex;
            align-items: center;
            justify-content: center;
            color:#F0FDF4;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            border: 0;
            cursor: pointer;
            span{
              margin-left: 10px;
            }
          }
          .url-btn{
            width: 48%;
            height: 50px;
            margin-top: 40px;
            background-color:$brandColor;
            display: flex;
            align-items: center;
            justify-content: center;
            color:#F0FDF4;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            border: 0;
            cursor: pointer;
          }
        }
      }
    }
    .address-info-block{
      width: 630px;
      position: relative;
      .map-block{
        width: 100%;
        height: 550px;
        padding-bottom: 270px;
        .yandexMap{
          width: 100%;
          height: 100%;
          .ymaps-2-1-79-map{
            width: 100%!important;
            height: 100%!important;

          }
        }
      }
      .contact-info-block{
        width: 70%;
        position: absolute;
        bottom: 0;
        left: -6px;
        padding: 30px 20px 40px 30px;
        background:  #F8F8F8;
        box-shadow: 0px 4px 99px 0px #83838340;

        .contact-info-title{
          color: #000;
          font-family: SpaceMono;
          font-size: 26px;
          //font-style: italic;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 1.3px;
          text-transform: uppercase;
        }
        .address-block{
          margin-top: 24px;
          .address-title{
            color: #B9B9B9;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0.3px;
          }
          .address{
            color:#000;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.4px;
            margin-top: 6px;
          }
        }
        .working-hours-block,.phone-number-block{
          margin-top: 24px;
          .working-hours-title,.phone-number-title{
            color: #B9B9B9;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0.3px;
          }
          .working-hours,.phone-number{
            color:#000;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.4px;
            margin-top: 4px;
            a{
              color:#000000!important;
            }
          }
          .phone-number{
            text-decoration-line: underline;
            a{
              font-family: Montserrat;
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .contact-block{
      flex-direction: column;
      padding: 80px $mainPadding 100px;
      .contact-us{
        width: 100%;
        margin-bottom: 60px;
        .contact-us-title{
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
        }
        .contact-us-description{
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 22px;
        }
        .contact-input-wrapper{
          margin-top: 40px;
          .top-input-block,.mid-input-block{
            flex-direction: column;
            margin-bottom: unset;
            .custom-input-group{
              width: 100%;
              margin-top: 22px;
              input{
                width:calc(100% - 32px);
              }
            }
          }
          .custom-input-group{
            margin-top: 22px;
            textarea{
              width:calc(100% - 32px);
            }
          }
        }
      }
      .address-info-block{
        width: 100%;
        .map-block{
          position: relative;
          z-index: -1;
          padding-bottom: 240px;
        }
        .contact-info-block{
          width: 80%;
          left: 0;
          .contact-info-title{
            font-size: 16px;
          }
          .address-block{
            .address{
              font-size: 14px;
            }
          }
          .working-hours-block{
            .working-hours{
              font-size: 14px;
            }
          }
          .phone-number-block{
            .phone-number{
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px){
    .contact-block{
      .address-info-block{
        .map-block{
          //padding-bottom: 240px;
        }
        .contact-info-block{
          padding: 20px 10px 20px 20px;
          width: 80%;
        }
      }
    }
  }
  @media screen and (max-width: 500px){
    .contact-block{
      .address-info-block{
        .map-block{
          padding-bottom: 230px;
        }
        .contact-info-block{
          width: 70%;
          .contact-info-title{
            line-height: 26px;
          }
        }
      }
    }
  }
}
