@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.mainBlock-wrapper {
  width: 100%;
  position: relative;
  background-color: black;

  .main-img-block {
    height: 100vh;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media screen and (max-width: 1400px) {
        height: 100%;
      }
    }
  }

  .main-img-responsive-block {
    display: none;
  }

  .main-text-block {
    width: 100%;
    position: absolute;
    z-index: 1000;
    bottom: 124px;
    padding: 0 $mainPadding;

    .main-block-title {
      width: 1080px;
      font-size: 94px;
      font-family: SpaceMono;
      //text-transform: uppercase;
      font-weight: 700;
      line-height: 139px;
      letter-spacing: 0;
      text-align: left;
      color: transparent;
      -webkit-text-stroke: 1px white;

      p {
        margin: unset !important;

        span {
          font-family: Montserrat;
          //text-transform: uppercase!important;
          font-weight: 700 !important;
          line-height: 139px !important;
          letter-spacing: 0 !important;
          text-align: left;
          color: transparent;
          -webkit-text-stroke: 1px white !important;
        }
      }

      @media screen and (max-height: 800px) {
        font-weight: 500;
        p {
          span {
            font-weight: 500 !important;
          }
        }
      }
    }

    .main-block-link {
      width: 240px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      line-height: 110.377%;
      margin-top: 40px;
      cursor: pointer;
      overflow: hidden;

      svg {
        margin-left: 4px;
        transition: transform 0.2s;
        transform: rotate(0deg);
      }

      &:hover {
        svg {
          transform: rotate(0deg) translateX(6px);
        }
      }
    }
  }

  .main-animation-block {
    position: absolute;
    z-index: 1000;
    bottom: 114px;
    right: $mainPadding;

    img {
      width: 30px;
      height: 66px;
      padding-top: 20px;
    }
  }

  @media screen and (max-width: 1050px) {
    .main-img-block {
      display: none;
    }
    .main-animation-block {
      display: none;
    }
    .main-img-responsive-block {
      display: flex;
      position: relative;
      padding-top: 130%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .main-text-block {
      bottom: 80px;

      .main-block-title {
        width: 100%;
        font-size: 55px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
      }

      .main-block-link {
        color: #FFF;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 110.377%;
        margin-top: 30px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .main-text-block {
      bottom: 60px;

      .main-block-title {
        width: 100%;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;

        p {
          span {
            line-height: 126.6% !important;
          }
        }
      }
    }
  }
}
