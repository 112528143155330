@import "./src/assets/styles/mainVariables";

.trainer-modal{
  .ant-modal{
    width: 50%!important;
    .ant-modal-content{
      border-radius: unset;
      .ant-modal-close{
        &:hover{
          svg{
            width: 100px;
            path{
              fill: #0e0e0e;
            }
          }
        }
      }
      @media screen and (max-width: 1050px){
        padding: 16px;
      }
    }
  }
  .trainer-modal-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .trainer-modal-img-wrapper{
      width: 48%;
      .trainer-modal-img{
        position: relative;
        padding-top: 100%;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .web-wrapper{
        display: flex;
      }
    }
    .trainer-modal-info{
      width: 50%;
      display: flex;
      flex-direction: column;
      position: relative;
      .trainer-modal-header{
        width: 100%;
        margin-bottom: 8px;
        .trainer-modal-title{
          color: #000;
          font-family: Montserrat;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 126.6%;
        }
        .trainer-modal-position{
          color: #8B8B8B;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
      .trainer-modal-bio{
        color:#262626;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.5px;
      }
      .mobile-wrapper{
        display: flex;
        width: 100%;
        height: 100%;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .ant-modal{
      width: 90%!important;
    }
    .trainer-modal-wrapper{
      flex-direction: column;
      padding-top: 22px;
      .trainer-modal-img-wrapper{
        width: 100%;
        .web-wrapper{
          display: none;
        }
      }
      .trainer-modal-info{
        width: 100%;
        margin-top: 22px;
        .trainer-modal-position{
          margin-top: 4px;
        }
        .trainer-modal-bio{
          margin-top: 22px;
        }
        .mobile-wrapper{
          justify-content: flex-start;
        }
      }
    }
  }
}