body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: SpaceMono,Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    scroll-behavior: smooth;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-tabs-top >.ant-tabs-nav::before,:where(.css-dev-only-do-not-override-14wwjjs).ant-tabs-bottom >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-14wwjjs).ant-tabs-top >div>.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-14wwjjs).ant-tabs-bottom >div>.ant-tabs-nav::before{
    border-bottom: unset!important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible), :where(.css-dev-only-do-not-override-14wwjjs).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible), :where(.css-dev-only-do-not-override-14wwjjs).ant-tabs .ant-tabs-tab-btn:active, :where(.css-dev-only-do-not-override-14wwjjs).ant-tabs .ant-tabs-tab-remove:active{
    color:#EE5626!important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-tabs .ant-tabs-tab:hover{
    color:#EE5626!important;
}
.ant-tabs-nav-operations{
    display: none!important;
}
.ant-modal-footer{
    display: none;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-tabs-top >.ant-tabs-nav .ant-tabs-nav-wrap::after{
    box-shadow: unset!important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-tabs-top >.ant-tabs-nav .ant-tabs-nav-wrap::before{
    box-shadow: unset!important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-collapse .ant-collapse-content>.ant-collapse-content-box{
    padding: 0 16px!important;
}
:where(.css-14wwjjs).ant-tabs-top >.ant-tabs-nav::before{
    border-bottom: unset!important;
}
.ymaps-2-1-79-gotoymaps{
    display: none!important;
}
.ymaps-2-1-79-_checked{
    background-color: white!important;
}
.ymaps-2-1-79-copyright,.ymaps-2-1-79-copyright_logo_no{
    display: none!important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-dropdown .ant-dropdown-menu{
    border-radius: unset!important;
    background-color: #0E0E0E!important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.377%;
    :hover{
        color: #A9A9A9;
    }
}
:where(.css-14wwjjs).ant-dropdown .ant-dropdown-menu, :where(.css-14wwjjs).ant-dropdown-menu-submenu .ant-dropdown-menu{
    border-radius: unset!important;
    background-color: #0E0E0E!important;
}
:where(.css-14wwjjs).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.377%;
    :hover{
        color: #A9A9A9;
    }
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-modal .ant-modal-close:hover {
    color: unset!important;
    background-color: unset!important;
    text-decoration: none!important;
}
:where(.css-14wwjjs).ant-modal .ant-modal-close:hover{
    color: unset!important;
    background-color: unset!important;
    text-decoration: none!important;
}


a:link { text-decoration: none !important; }
a:hover { text-decoration: none !important; }
a:active { text-decoration: none !important; }
a:visited { text-decoration: none !important; }

p{
    margin-block-start:unset!important;
    margin-block-end:unset!important;
    margin-inline-start:unset!important;
    margin-inline-end:unset!important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon{
    display: flex;
    align-self: start;
    padding-inline-end: unset!important;
    padding-top: 10px;
}
