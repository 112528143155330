@import "./src/assets/styles/mainVariables";

.staff-card-wrapper{
  width: 100%;
  .staff-card-img{
    position: relative;
    padding-top: 100%;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .staff-card-info{
    padding: 20px 22px;
    background-color: #F8F8F8;
    .staff-card-name{
      color:#262626;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.56px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .staff-card-position{
      color: #8B8B8B;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 39.793px;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  @media screen and (max-width: 1050px){
    .staff-card-info{
      .staff-card-name{
        font-size: 24px;
      }
      .staff-card-position{
        font-size: 20px;
      }
    }
  }
}