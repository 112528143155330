@import "./src/assets/styles/mainVariables";

.workout-card-wrapper{
  width: calc(96% / 4);
  gap: 25px;
  position: relative;
  .workout-card-img{
    position: relative;
    padding-top: 79.7%;
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
  .workout-card-text{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    .workout-card-title{
      color: #FFF;
      text-align: center;
      font-family: Montserrat;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
    .workout-card-description{
      color: #FFF;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 8px;
    }
    .workout-card-btn{
      width: 80%;
      height: 50px;
      margin: 20px 0 38px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: unset;
      background-color: #262626;
      color: #F0FDF4;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      cursor: pointer;
      &:hover{
        background-color:$brandColor;
      }
    }
  }

  @media screen and (max-width: 1050px){
    width: 100%;
    margin-top: 22px;
  }
}
