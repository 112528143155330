@import "./src/assets/styles/mainVariables";
@import "./src/assets/styles/fonts";

.event-detail-wrapper {
  //overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .event-detail-header {
    width: 100%;
    padding-top: 124px;
    background-color: black;
    position: relative;

    .event-detail-members {
      position: absolute;
      bottom: 20px;
      right: 0;
      width: 220px;
      height: 26px;
      padding: 0 8px;
      //overflow: hidden;
      background: #7CD780;
      color: #FFF;
      text-align: center;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.538px;
      letter-spacing: -0.137px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 4px;
      }
    }

    .event-detail-img-block {
      position: relative;
      padding-top: 33.8%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .event-detail-info-wrapper {
    padding: 80px $mainPadding 120px $mainPadding;
    display: flex;
    justify-content: space-between;

    .event-detail-info-block {
      width: 70%;

      .event-detail-date {
        color: $brandColor;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.36px;
        text-transform: capitalize;
        margin-bottom: 20px;

        .separator {
          margin: 0 8px;
        }

        @media all and (max-width: 1100px) {
          span {
            font-size: 16px;
          }
        }
      }

      .event-detail-title {
        color: #292929;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 129.1%;
        letter-spacing: -0.2px;
      }

      .event-detail-description {
        color: #292929;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-top: 40px;

        p {
          margin: unset;

          a {
            color: $brandColor !important;
          }

          span {
            color: #292929 !important;
            font-family: Montserrat !important;
            font-size: 20px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 30px;
            margin-top: 40px;
          }
        }
      }

      .event-detail-info-bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #B9B9B9;
        padding-top: 20px;
        margin-top: 50px;

        .event-detail-date {
          color: #B9B9B9;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 35.768px;
          letter-spacing: -0.4px;

          span {
            color: #B9B9B9;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28.614px;
            letter-spacing: -0.4px;
            margin-left: 4px;
          }
        }

        .event-detail-view {
          color: #B9B9B9;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 28.614px;
          letter-spacing: -0.4px;
          display: flex;
          align-items: center;

          svg {
            margin-right: 4px;
          }
        }
      }
    }

    .download-wrapper {
      .download_block {
        position: fixed;
      }
    }

    .download-wrapper {
      width: 336px;
      margin-left: 74px;
      padding-top: 12px;

      .download_block {
        padding: 20px;
        border-radius: 4px;
        border: 1px solid #E8E8E8;
        background: #FFF;
        position: sticky;
        position: -webkit-sticky;
        top: 24px;
        bottom: auto;

        span {
          color: #000;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-transform: uppercase;
          font-family: inherit;
        }

        p {
          color: #000;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-transform: uppercase;
          font-family: inherit;
        }

        .download_btn {
          width: 100%;
          height: 42px;
          margin-top: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $brandColor;
          color: #FFF;
          text-align: center;
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.061px;
        }
      }
    }
  }

  .event-detail-article-wrapper {
    //width: 100%;
    padding: 0 $mainPadding 120px $mainPadding;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .event-detail-article-header {
      width: 652px;
      padding: 0 $mainPadding;
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .event-detail-article-title {
        color: #000;
        font-family: SpaceMono;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
        text-transform: uppercase;
      }

      .event-detail-article-description {
        margin-top: 22px;
        color: #8B8B8B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }

    .event-detail-article-block {
      width: 100%;
      display: flex;
      //align-items: center;
      padding: 0 $mainPadding;
      gap: 40px;

      .event-detail-article {
        width: calc(100% / 3);
      }
    }
  }

  @media screen and (max-width: 1050px) {
    overflow: hidden;
    .event-detail-header {
      padding-top: 120px;

      .event-detail-img-block {
        padding-top: 62.5%;
      }
    }
    .event-detail-info-wrapper{
      flex-direction: column;
      width: 94%;
      padding: 80px $mainPadding;
      .event-detail-info-block {
        width: 100%;

        .event-detail-title {
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 129.1%;
          letter-spacing: -0.15px;
        }

        .event-detail-description {
          color: #292929;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
        }
      }
      .download-wrapper{
        width: 100%;
        margin-left: unset;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1000;
      }
    }
    .event-detail-article-wrapper {
      .event-detail-article-header {
        width: 100%;
        margin-bottom: unset;

        .event-detail-article-title {
          color: #000;
          text-align: center;
          font-family: SpaceMono;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.1%;
          letter-spacing: -0.6px;
          text-transform: uppercase;
        }

        .event-detail-article-description {
          color: #8B8B8B;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
        }
      }

      .event-detail-article-block {
        flex-direction: column;

        .event-detail-article {
          width: 100%;
          margin-top: 62px;
        }
      }
    }
  }
}
