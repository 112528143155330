@import "./src/assets/styles/mainVariables";


.footer-wrapper{
  padding-top: 50px;
  background-color: black;
  overflow: hidden;

  .footer-top-block{
    padding: 0 $mainPadding 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      .orange-logo {
        width: 66px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .nav-menu {
      display: flex;
      align-items: center;
      list-style-type:none;
      z-index: 1000;

      .active {
        .nav-item {
          color: $brandColor;
        }
      }
      a{
        text-decoration: unset;
        .nav-item {
          margin-left: 26px;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0;
          text-align: left;
          color: white;
          display: flex;
          align-items: center;

          svg{
            margin-left: 4px;
          }
          &:hover {
            color: $brandColor;
            svg{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
        &:last-child{
          margin-left: 80px;
        }
      }
    }
  }
  .footer-responsive-block{
    display: none;
  }
  .footer-bottom-block{
    padding: 14px $mainPadding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(139, 139, 139, 0.3);
    .footer-copyright-block{
      display: flex;
      align-items: center;
      span{
        color:#8B8B8B;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        &:nth-child(2){
          margin-left: 4px;
        }
      }
      .footer-company-link{
        color:$brandColor;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-decoration-line: underline;
        margin-left: 4px;
      }
    }
    .footer-social-media-block{
      display: flex;
      align-items: center;
      svg{
        cursor: pointer;
        margin-left: 34px;
        &:hover{
            path{
              fill:$brandColor;
            }
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding-top: 38px;
    &.margin-wrapper{
      margin-bottom: 130px;
    }
    .footer-top-block{
      display: none;
    }
    .footer-responsive-block{
      display: flex;
      flex-direction: column;
      padding: 0 $mainPadding 32px;
      .logo-block{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a{
          text-decoration: unset;
          .nav-item {
            margin-left: 42px;
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            color: #8B8B8B;
            display: flex;
            align-items: center;

            svg{
              margin-left: 4px;
              path{
                stroke: #8B8B8B;
              }
            }
            &:hover {
              color: $brandColor;
              svg{
                path{
                  stroke:$brandColor;
                }
              }
            }
          }
        }
      }
      .page-wrapper{
        margin-top: 60px;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        gap: 10px;
        .page-block{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          a{
            text-decoration: unset;
            margin-top: 20px;
            .nav-item {
              font-size: 16px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 0;
              text-align: left;
              color: white;
              display: flex;
              align-items: center;

              svg{
                margin-left: 4px;
              }
              &:hover {
                color: $brandColor;
                svg{
                  path{
                    stroke:$brandColor;
                  }
                }
              }
            }
          }
        }
      }
      .footer-responsive-social-medias{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        svg{
          cursor: pointer;
          margin-left: 34px;
          &:hover{
            path{
              fill:$brandColor;
            }
          }
        }
      }
    }
    .footer-bottom-block{
      .footer-copyright-block{
        flex-direction: column;
        align-items: unset;
        span{
          font-size: 14px;
          margin-left: unset!important;
        }
      }
      .footer-social-media-block{
        display: none;
      }
    }
  }
}