@import "./src/assets/styles/mainVariables";

.classes-modal {
  .ant-modal {
    width: 70% !important;

    .ant-modal-content {
      padding: unset;
      border-radius: unset;
      background-color: black;

    }
  }

  .classes-modal-wrapper {
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .classes-modal-info {
      background-color: black;
      padding: 30px;

      .classes-modal-img {
        position: relative;
        padding-top: 34.7%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .classes-modal-title {
        color: #FFF;
        font-size: 22px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        line-height: 126.6%;
        text-transform: uppercase;
        margin-top: 24px;
      }

      .classes-modal-description {
        color: #8B8B8B;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-top: 16px;
      }
    }

    .classes-modal-trainers {
      background-color: white;
      padding: 30px;

      .classes-modal-trainers-title {
        color: #262626;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 126.6%;
      }

      .classes-modal-trainers-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 16px;

        .classes-modal-trainers-card {
          width: calc(96% / 3);
          .staff-card-wrapper{
            .staff-card-info{
              .staff-card-name{
                color: #262626;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.32px;
              }
              .staff-card-position{
                color: #8B8B8B;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.891px;
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .ant-modal {
      width: 96% !important;
    }
    .classes-modal-wrapper {
      .classes-modal-info {
        .classes-modal-img{
          padding-top: 58.6%;
        }
        .classes-modal-title{
          color: #FFF;
          font-family: Montserrat;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 126.6%;
          margin-top: 25px;
        }
        .classes-modal-description{
          color: #8B8B8B;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 21.5px;
          margin-top: 16px;
        }
      }
      .classes-modal-trainers{
        .classes-modal-trainers-title{
          color:#262626;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 126.6%;
        }
        .classes-modal-trainers-wrapper{
          flex-wrap: unset;
          overflow: scroll;
          .classes-modal-trainers-card{
            width: 100%;
          }
        }
      }
    }
  }
}