@import "./src/assets/styles/mainVariables";

.visit-modal {
  .ant-modal {
    width: 34% !important;

    .ant-modal-content {
      padding: unset;
      border-radius: unset;

      .ant-modal-close {
        height: 24px;
        width: 24px;
        top: 6px;
        right: 6px;
        &:hover{
            svg{
              width: 100px;
              path{
                fill: #0e0e0e;
              }
            }
        }
        svg {
          path {
            fill: #B9B9B9;
          }
        }
      }
    }

    .visit-modal-wrapper {
      padding: 36px 36px 30px 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .visit-modal-header {
        width: 308px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .visit-modal-title {
          color: #000;
          font-family: Montserrat;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 126.6%;
        }

        .visit-modal-description {
          color: #8B8B8B;
          text-align: center;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 22px;
        }
      }

      .visit-modals-inputs {
        width: 100%;
        margin-top: 24px;

        .custom-input-group {
          margin-top: 20px;

          input {
            height: 48px;
            background: #F3F3F3;
            border: unset;
            color: #262626;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.32px;

            &::placeholder {
              color: #8B8B8B;
            }
          }
        }

        .send-visit {
          height: 48px;
          width: 100%;
          margin-top: 40px;
          background-color: $brandColor;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFF;
          text-align: center;
          font-family: Montserrat;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.061px;
          border: unset;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .ant-modal {
      width: 92% !important;

      .visit-modal-wrapper {
        .visit-modal-header {
          width: 100%;
        }
      }
    }
  }
}
